import api from "@/api/auth";

const auth = {
  state: {
    session:
      localStorage.getItem("session") != null
        ? JSON.parse(localStorage.getItem("session"))
        : {},
  },
  getters: {
    sessionData: (state) => state.session,
    isLoggedIn: (state) => !!state.session.access_token,
  },
  mutations: {
    setSessionData(state, data = null) {
      if (!data) return;
      localStorage.setItem("session", JSON.stringify(data));
      state.session = data;
    },
    setLogout(state) {
      localStorage.clear();
      state.session = {};
      console.log("Local Storage Cleared");
    },
  },
  actions: {
    async initSession({ commit, dispatch, getters }, payload = null) {
      if (!payload) return null;
      const { data } = await api.login(payload);

      if (data.access_token) {
        commit("setSessionData", data);
        dispatch("fetchUserData");
      }

      if (data.tenant) {
        /* Tenant User */
        console.log("AUTH TENANT: ");
        console.log(data.tenant);
        commit("setCurrentTenant", data.tenant);
        commit("setCurrentTenantUser", data.tenantUser);

        if (JSON.stringify(data.tenantUser.roles).includes('ROLE_TENANT_ADMIN')) {
          commit("setHasTenantAdminRole", true);
          commit("setCurrentUserType", getters['userTypes'][2]); //Tenant Admin
        } else if (JSON.stringify(data.tenantUser.roles).includes('ROLE_ADMIN')) {
          commit("setCurrentUserType", getters['userTypes'][0]); //Master Admin
        } else {
          commit("setCurrentUserType", getters['userTypes'][1]); //Tenant User
        }
      }

      return data;
    },

    async signOut({ commit }) {
      // api.logout().finally(() => {
        await commit("setLogout");
        await commit("clearUserData");

        window.location.href = process.env.VUE_APP_OAUTH_LOGOUT;
    },

    async signUserUp({ commit, dispatch }, payload = null) {
      if (!payload) return null;

      const { data } = await api.register(payload);

      if (data.status === "Success") {
        commit("setSessionData", data.data);

        if (data.data.token) {
          dispatch("fetchUserData");
        }
      }

      return data;
    },
  },
};
export default auth;
